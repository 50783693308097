<template>
    <NewsletterSubscriber v-slot="subscriber">
        <form :class="{ busy: subscriber.busy }" class="form-inline">
            <TextField
                id="home-newsletter-nome"
                v-model="subscriber.fields.nome"
                autocomplete="name"
                placeholder="Nome:"
                type="name"
            />
            <TextField
                id="home-newsletter-email"
                v-model="subscriber.fields.email"
                placeholder="E-mail"
                type="email"
            />
            <LoadingButton
                :loading="subscriber.busy"
                class="btn btn-lg flex-child-fixed"
                @click="subscriber.subscribe"
            >
                Cadastrar
            </LoadingButton>
        </form>
    </NewsletterSubscriber>
</template>

<script>
    import LoadingButton from '../common/LoadingButton';
    import NewsletterSubscriber from '../newsletter/NewsletterSubscriber';
    import TextField from '../../../../../submodules/src/components/input/text-field/TextField.vue';

    export default {
        el: '#newsletter-home-vue',
        name: 'HomeNewsletter',
        components: { NewsletterSubscriber, LoadingButton, TextField },
    };
</script>
